import { useContext, useRoute } from '@nuxtjs/composition-api';

import getCategoryUrlPath from '~/diptyqueTheme/customMiddleware/extensions/queries/getCategoryUrlPath.gql';
import getProductUrlPath from '~/diptyqueTheme/customMiddleware/extensions/queries/getProductUrlPath.gql';
import { Logger } from '~/helpers/logger';

export function useRequestPath() {
  const {
    error: nuxtError,
    app: {
      i18n,
      $vsf: { $magento }
    }
  } = useContext();
  const route = useRoute();
  const { path } = route.value;

  const getFullProductBasedOnFullRequestPath = async function (specificRoute = path) {
    try {
      const cleanedPath: string = getCleanedPathForPDPandPlp(specificRoute);
      const { data, errors } = await $magento.api.customQuery({
        query: getProductUrlPath,
        queryVariables: {
          url: cleanedPath
        }
      });

      if (!data) {
        nuxtError({ statusCode: 404 });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const results = data?.route;

      if (!results) {
        if (errors) {
          console.error(errors);
        }
        nuxtError({ statusCode: 404 });
        return;
      }

      return results;
    } catch (error) {
      Logger.error('Get Full Product Data from URL path by route [ERROR]', error);
      console.error(error);
    }
  };

  const getFullCategoryBasedOnRequestPath = async function (specificRoute = path) {
    try {
      const cleanedPath: string = getCleanedPathForPDPandPlp(specificRoute);
      const { data, errors } = await $magento.api.customQuery({
        query: getCategoryUrlPath,
        queryVariables: {
          url: cleanedPath
        }
      });

      if (!data) {
        nuxtError({ statusCode: 404 });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const results = data?.route;

      if (!results) {
        if (errors) {
          console.error(errors);
        }
        nuxtError({ statusCode: 404 });
        return;
      }

      return results;
    } catch (error) {
      Logger.error('Get Full Category Data from URL path by route [ERROR]', error);
      console.error(error);
    }
  };

  const getPath = (urlPath: string) => urlPath.replace(`/${i18n.locale}/`, '').trim();

  const getCleanedPathForPDPandPlp = function (urlPath: string) {
    if (urlPath.includes('p/') || urlPath.includes('l/')) {
      return getPath(urlPath);
    }
  };

  return {
    getFullProductBasedOnFullRequestPath,
    getFullCategoryBasedOnRequestPath
  };
}
