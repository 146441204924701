import { useContext } from '@nuxtjs/composition-api';

import { useApi, useCart, useUiNotification, useUser } from '~/composables';
import userToken from '~/diptyqueTheme/customQueries/magento/userToken';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { lsGet, lsSet, lsRemove } from '~/diptyqueTheme/composable/useLocalStorage';

import { loginType, userTokenResponseInterface, useSocialLoginInterface } from './types';

export function useSocialLogin(): useSocialLoginInterface {
  const { app } = useContext();
  const { query } = useApi();
  const { state } = app.context.$vsf.$magento.config;
  const apiState = app.context.$vsf.$magento.config.state;
  const { setCart } = useCart();
  const { load: loadUser } = useUser();
  const { send: sendNotification } = useUiNotification();
  const customerStore = useCustomerStore();
  const loginWithFacebook = async () => {
    window.FB.init({
      appId: process.env.VSF_FB_CLIENT_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v16.0'
    });
    window.FB.login(
      (response) => {
        if (response?.authResponse) {
          const accessToken = response.authResponse.accessToken;
          setLocalStorageAuthStrategy('facebook');
          getTokenAndSetUser(accessToken, 'facebook');
        }
      },
      { scope: 'public_profile,email' }
    );
  };

  const loginWithGoogle = () => {
    window.google.accounts.id.initialize({
      client_id: process.env.VSF_GOOGLE_CLIENT_ID,
      callback: googleCallback,
      context: 'signin',
      scope: 'email profile'
    });
    window.google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        window.google.accounts.id.prompt();
      }
    });
  };

  const googleCallback = async (response) => {
    const token = response.credential;
    setLocalStorageAuthStrategy('google');
    await getTokenAndSetUser(token, 'google');
  };

  const setUserCart = async () => {
    const currentCartId = apiState.getCartId();
    const cart = await app.context.$vsf.$magento.api.customerCart();
    const newCartId = cart.data.customerCart.id;
    if (newCartId && currentCartId && currentCartId !== newCartId) {
      const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts({
        sourceCartId: currentCartId,
        destinationCartId: newCartId
      });
      setCart(dataMergeCart.mergeCarts);
      apiState.setCartId(dataMergeCart.mergeCarts.id);
    } else {
      setCart(cart.data.customerCart);
    }
  };

  const getTokenAndSetUser = async (token: string, login_type: loginType) => {
    try {
      const { data, errors }: userTokenResponseInterface = await query(userToken, {
        token,
        login_type
      });
      if (errors?.length) {
        sendNotification({
          icon: 'error',
          id: Symbol(`${login_type}-social_login_error`),
          message: app.i18n.t('We are not able to sign you in with {login_type}. Please verify your social settings or switch to another method.', {
            login_type: login_type.charAt(0).toUpperCase() + login_type.slice(1)
          }) as string,
          persist: false,
          title: 'Social login error',
          type: 'danger',
          area: 'top'
        });
        return;
      }
      if (data?.getSocialLoginToken) {
        state.setCustomerToken(data.getSocialLoginToken);
        customerStore.setIsLoggedIn(true);
        await loadUser({
          customQuery: {
            customer: 'customerWithOptions'
          }
        });
        await setUserCart();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setLocalStorageAuthStrategy = (strategy) => {
    lsSet('strategy', strategy);
  };

  return {
    loginWithGoogle,
    loginWithFacebook
  };
}
